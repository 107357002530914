<template>
  <div>
    <div v-if="isAutonomous">
      <h2 class="custom-title-secondary mb-1"> De onde você é? </h2>
    </div>
    <div v-else>
      <div v-if="isOpening">
        <h2 class="custom-title-secondary mb-1">
          Em qual estado e cidade sua empresa será registrada?
        </h2>
      </div>
      <div v-else>
        <h2 class="custom-title-secondary mb-1">
          Em qual estado e cidade sua empresa está registrada?
        </h2>
        <p class="custom-normal-text">
          Se não se lembrar, é só olhar o seu cartão CNPJ. Tá tudo escrito lá
          ;-)
        </p>
      </div>
    </div>
    <v-row class="mb-6">
      <v-col cols="12" class="pb-0">
        <v-autocomplete
          :search-input.sync="nameState"
          v-model="stateSelected.uf"
          :items="states"
          item-text="name"
          item-value="initials"
          label="Digite o seu estado"
          fab
          :filter="setFilterStates"
          no-data-text="Estado não encontrado"
          :rules="rules.state"
          required
          auto-select-first
          :menu-props="{ maxHeight: 150 }"
          @change="changeState"
        >
          <template slot="append" v-if="loadingState">
            <v-progress-circular
              size="20"
              width="3"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          :search-input.sync="searcCity"
          v-model="formUser.city.id"
          :loading="loadingCity"
          :items="listCities"
          item-text="name"
          item-value="id"
          label="Digite sua cidade"
          :no-data-text="textNoData"
          :readonly="!isEditing"
          :disabled="!isEditing"
          :rules="rules.city"
          required
          auto-select-first
          :menu-props="{ maxHeight: 150 }"
          @change="onAnswer($event)"
        >
          <template slot="append" v-if="loadingCity">
            <v-progress-circular
              size="20"
              width="3"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import {
  cityMethods,
  cityComputed,
  stateMethods,
  stateComputed,
} from '@state/helpers'

export default {
  props: {
    answer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searcCity: '',
      nameState: '',
      loadingCity: false,
      loadingState: false,
      currentAnswer: this.answer,
      viewItems: true,
      stateSelected: {
        uf: '',
      },
      debounce: null,
      rules: {
        state: [(v) => !!v || 'Selecione um estado'],
        city: [(v) => (!!v && !!this.searcCity) || 'Selecione uma cidade'],
      },
    }
  },
  computed: {
    ...cityComputed,
    ...stateComputed,
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
      isAutonomous: 'formDataUser/isAutonomous',
    }),
    textNoData() {
      if (this.viewItems && this.cities.length == 0) {
        return 'Cidade não encontrada'
      }
      return 'Digite o nome da sua cidade'
    },
    listCities() {
      if (this.viewItems) {
        return this.cities
      } else {
        return []
      }
    },
    isEditing() {
      return !!this.stateSelected.uf && !this.loadingState
    },
  },
  methods: {
    ...cityMethods,
    ...stateMethods,
    changeState() {
      this.$store.commit('city/SET_CITIES', [])
      this.searcCity = ''
      this.viewItems = false
    },
    changeIfNullVariables() {
      this.searcCity = this.searcCity ? this.searcCity : ''
      this.stateSelected.uf = this.stateSelected.uf ? this.stateSelected.uf : ''
    },
    async initializeStateAndCity() {
      if (!this.formUser.city.id) {
        this.stateSelected.uf = ''
        this.searcCity = ''
        this.viewItems = false
        return
      }
      await this.getCity(this.formUser.city.id)
      this.stateSelected.uf = this.city.uf
      this.searcCity = this.city.name
    },
    setFilterStates(item, queryText) {
      return this.$options.filters
        .removeAccents(item.name)
        .toLowerCase()
        .includes(this.$options.filters.removeAccents(queryText).toLowerCase())
    },
    onAnswer(value) {
      if (!value) return
      this.$emit('onAnswer', { idCity: value })
    },
    searchCities() {
      this.loadingCity = true
      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.serchCityByStateAndName({
          uf: this.stateSelected.uf,
          city: this.searcCity,
        })
        this.loadingCity = false
      }, 500)
    },
  },
  async created() {
    this.loadingState = true
    await this.getAllStates()
    this.loadingState = false
    this.initializeStateAndCity()
  },
  watch: {
    searcCity(value) {
      if (!this.stateSelected.uf) return
      if (!value) return
      this.viewItems = true
      this.searchCities()
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
